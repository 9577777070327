import React from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

const MailchimpFormContainer = styled.div`
  & input,
  textarea {
    color: #000;

    margin-left: 0;
    width: 100%;
    border: 0;
    -webkit-appearance: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  & input {
    height: 22px;
    position: relative;
    line-height: 1;

    width: calc(100% - 80px);
    border-bottom: 1px solid #000 !important;

    border-radius: 0;
    -webkit-appearance: none;
  }

  & input::-webkit-input-placeholder {
    color: #000;
  }
  & input::-moz-placeholder {
    color: #000;
    opacity: 1;
  }
  & input:-ms-input-placeholder {
    color: #000;
  }
  & input:-moz-placeholder {
    color: #000;
    opacity: 1;
  }
  & input::placeholder {
    color: #000;
  }
`;

const SignUpButton = styled.button`
  text-align: center;
  padding: 0;
  margin: 0 0 0 10px;

  // width: 90px;
  width: 70px;
  height: 24px;

  border: 1px solid #000;
  // border-bottom: 1px solid #000;
  -webkit-appearance: none;

  background-color: #fff;
  color: #000;

  cursor: pointer;

  transition: 250ms all ease;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #fff;
    background-color: #000;
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 3px;
`;

class Mailchimp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      EMAIL: null,
      message: "Newsletter sign up",
    };

    this.submitMailchimp = this.submitMailchimp.bind(this);
  }

  handleChangeMailchimp = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  submitMailchimp = e => {
    e.preventDefault();
    e.target.reset();

    addToMailchimp(this.state.EMAIL)
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg;
        }
        this.setState({
          message: msg,
        });
        console.log("msg", msg);
      })
      .catch(err => {
        this.setState({
          message: "This email is already subscribed",
        });
        console.log("err", err);
      });
  };

  render(props) {
    return (
      <MailchimpFormContainer>
        <form onSubmit={this.submitMailchimp}>
          <SignUpContainer>
            <input
              type="email"
              onChange={this.handleChangeMailchimp}
              placeholder={this.state.message}
              name="EMAIL"
            />

            <SignUpButton type="submit" disabled={this.state.EMAIL === null}>
              Submit
            </SignUpButton>
          </SignUpContainer>
        </form>
      </MailchimpFormContainer>
    );
  }
}

export default Mailchimp;
