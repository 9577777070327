import React, { useState, useEffect, useMemo } from "react";
import { graphql, Link } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import styled from "styled-components";
import Helmet from "react-helmet";
import Div100vh from "react-div-100vh";
import Media from "react-media";
import Mailchimp from "../components/mailchimp/mailchimp";

const NavBar = styled.nav`
  position: fixed;
  right: 37px;
  bottom: 149px;

  transform: translateZ(0) rotate(90deg);
  transform-origin: right;
  backface-visibility: hidden;
  outline: 1px solid transparent;

  z-index: 100;

  & ul,
  & li {
    margin: 0;
    list-style: none;
  }

  & li {
    padding-left: 20px;
  }

  & a {
    position: relative;
    perspective: 1px;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: hidden;
  }

  & a:hover:before,
  & .current:before {
    visibility: visible;
  }

  @media (hover: none) {
    a:hover:before {
      visibility: hidden;
    }

    a.current:before {
      visibility: visible;
    }
  }

  @media (max-width: 767px) {
    bottom: 19px;
  }

  @media (max-width: 500px) {
    right: 17px;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;

  height: 100%;

  & a {
    position: relative;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: hidden;
  }

  a:hover:before,
  & a.current:before {
    visibility: visible;
  }

  @media (hover: none) {
    a:hover:before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding: 50px 50px 0 0;
  }
`;

const ContactText = styled.div`
  flex: 0 0 50vw;
  padding: 50px 0 0 50px;
  max-width: 650px;

  width: 100%;
  position: relative;

  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & a {
    position: relative;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: visible;
  }

  & p {
    transform: translateY(
      ${props =>
        props.position ? "calc(100vh - 100% - 54px - 180px)" : "60px"}
    );
    transition: 400ms transform linear;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  @media (max-width: 1024px) {
    padding: 0;

    & p {
      transform: translateY(
        ${props =>
          props.position ? "calc(100vh - 100% - 4px - 180px)" : "60px"}
      );
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    flex: 0 0 100%;

    & p {
      position: relative;
      transform: none;
      transition: none;
    }
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  flex: 0 0 calc(50vw - 144px);
  max-width: 540px;

  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    flex: 0 0 calc(50vw - 110px);
  }

  @media (max-width: 767px) {
    justify-content: flex-start;

    height: auto;

    width: 100%;
    flex: 0 0 100%;

    padding: 60px 0;
  }
`;

const AboutTextInner = styled.div`
  position: absolute;
  right: 0;

  top: ${props => (props.position ? "110px" : "calc(100% - 200px)")};
  transition: 400ms top linear;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  -webkit-transform-style: preserve-3d;

  @media (max-width: 1024px) {
    top: ${props => (props.position ? "60px" : "calc(100% - 200px)")};
  }

  @media (max-width: 767px) {
    position: relative;
    right: 0;

    top: 0;
    transition: none;
  }
`;

const AboutText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  max-width: 540px;
  width: calc(50vw - 144px);
  height: 100%;

  & p {
    margin: 0;
  }

  & div {
    width: 100%;
    display: block;
  }

  @media (max-width: 768px) {
    flex: 0 0 calc(50vw - 110px);
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 200px;

    flex: 0 0 100%;
    transform: none;
    transition: none;
  }
`;

const MailchimpContainer = styled.div`
  align-self: flex-end;

  @media (max-width: 767px) {
    margin-top: 50px;
  }
`;

const MobileHeaderBarTop = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;

    background-color: #fff;
    height: 47px;
    z-index: 1;
  }
`;

const MobileHeaderBarBottom = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;

    background-color: #fff;
    height: 48px;
    z-index: 1;
  }
`;

const IS_BROWSER = typeof window !== "undefined";
const About = ({ location, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;
    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const [windowWidth, setWindowWidth] = useState(767);
  const [currentHash, setCurrentHash] = useState("#about");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      let windowWidthOnLoad = window.innerWidth;
      setWindowWidth(windowWidthOnLoad);

      document.body.style = "";

      // eslint-disable-next-line global-require
      require("smooth-scroll")('a[href*="#"]', {
        speed: 800,
        speedAsDuration: true,
        easing: "easeInOutCubic",
      });
    }
  });

  let contactStatus =
    location.state !== undefined && location.state !== null
      ? location.state.contact
      : false;

  return (
    <React.Fragment>
      <Helmet title={`About – Patrick Eakin Young`} />

      <MobileHeaderBarTop />
      <MobileHeaderBarBottom />

      <Div100vh style={{ height: "calc(100rvh - 60px)" }} id="about">
        <AboutContainer>
          <ContactText
            dangerouslySetInnerHTML={{
              __html: data.prismicAbout.data.text.html,
            }}
            position={contactStatus}
          />
          <ContactDetails>
            <AboutText position={contactStatus}>
              <AboutTextInner position={contactStatus}>
                <a href={`mailto:${data.prismicAbout.data.email}`}>
                  {data.prismicAbout.data.email}
                </a>
                {data.prismicAbout.data.phone && (
                  <p>{data.prismicAbout.data.phone}</p>
                )}
                <br />
                <br />
                <a
                  href={data.prismicAbout.data.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
                <br />
                <a
                  href={data.prismicAbout.data.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </AboutTextInner>
              <MailchimpContainer id="contact">
                <Mailchimp />
              </MailchimpContainer>
            </AboutText>
          </ContactDetails>
        </AboutContainer>
      </Div100vh>

      <NavBar>
        <ul style={{ display: "flex", padding: 0, width: "210px" }}>
          <li>
            <Link to={`/`}>Projects</Link>
          </li>

          <Media
            queries={{ medium: "(min-width: 768px)" }}
            defaultMatches={{ medium: windowWidth === 768 }}
            render={() => (
              <React.Fragment>
                <li>
                  <Link
                    to={`/about`}
                    state={{ contact: false }}
                    className={contactStatus === false && "current"}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/about`}
                    state={{ contact: true }}
                    className={contactStatus === true && "current"}
                  >
                    Contact
                  </Link>
                </li>
              </React.Fragment>
            )}
          />

          <Media
            queries={{ medium: "(max-width: 767px)" }}
            defaultMatches={{ medium: windowWidth === 767 }}
            render={() => (
              <React.Fragment>
                <li>
                  <a
                    href="#about"
                    className={currentHash === "#about" && "current"}
                    onClick={() => setCurrentHash("#about")}
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className={currentHash === "#contact" && "current"}
                    onClick={() => setCurrentHash("#contact")}
                  >
                    Contact
                  </a>
                </li>
              </React.Fragment>
            )}
          />
        </ul>
      </NavBar>
    </React.Fragment>
  );
};

export const aboutQuery = graphql`
  {
    prismicAbout {
      data {
        text {
          html
        }
        instagram
        email
        phone
        twitter
      }
    }
  }
`;

export default About;
